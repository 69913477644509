<template>
    <div class="admin_users">
        <div class="admin__controls">
            <a class="admin__button admin__button--blue admin__button--add" href="/admin/users/create">Add User</a>
            <pagination :data="users" v-on:pagination-change-page="getResults" ref="pagination"></pagination>
        </div>
        <div v-if="users.data.length > 0">
            <table>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Role</th>
                        <th colspan="2">Action</th>
                    </tr>
                </thead>
                <tbody>

                    <tr v-for="user in users.data">
                        <td>
                            <strong>{{ user.username }} </strong>
                        </td>
                        <td>
                            <strong>{{ user.user_role.display_name }}</strong>
                        </td>
                        <td class="action-column">
                            <a v-bind:href=" 'users/' + user.id + '/edit' " class="admin__button admin__button--blue admin__button--small">Edit</a>
                        </td>
                        <td class="action-column">
                            <button v-if="currentUser !== user.id" class="admin__button admin__button--small admin__button--danger" @click="destroy(user)">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            No Users
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'loadUsers',
            'currentUser'
        ],
        data() {
            return {
                users: this.loadUsers
            };
        },
        methods: {
            destroy(user) {
                let confirmed = confirm(user.first_name + ' ' + user.last_name + ' will be deleted! Are you sure?');

                if (confirmed) {
                    this.$http.delete('/admin/users/' + user.id).then(() => {
                        let index = this.users.data.indexOf(user);
                        this.users.data.splice(index, 1);
                    }).catch((response) => {
                        alert(response.response.data.message);
                    });
                }
            },
            getResults(page, popped) {
                if (typeof page === 'undefined') {
                    page = 1;
                }

                // Using vue-resource as an example
                this.$http.get('/admin/users?page=' + page + '&cache=' + Math.random()).then(response => {
                    if (!popped) {
                        window.history.pushState({page: page}, null, '/admin/user?page=' + page);
                    }

                    this.$refs.pagination.$data.allow_processing = true;
                    this.users = response.data;
                });
            }
        }
    };
</script>
<template>
    <div>
        <div class="admin__controls">
            <pagination :data="activities" v-on:pagination-change-page="getResults" ref="pagination"></pagination>
        </div>
        <div v-if="activities.data.length > 0">
            <table>
                <thead>
                <tr>
                    <th class="adminText">Date/Time</th>
                    <th class="adminText">User</th>
                    <th class="adminText">Activity</th>
                    <th class="adminText">Details</th>
                </tr>
                <tr>
                    <td colspan="6" height="10"></td>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="activity in activities.data" v-if="activity.user">
                        <td class="adminText">{{ activity.created_at | formatDate }}</td>
                        <td class="adminText">{{ activity.user.username }}</td>

                        <td class="adminText" v-if="activity.column === 'last_login_at' ">{{ activity.user.username}} logged in</td>
                        <td class="adminText" v-else="">{{activity.user.username}} {{activity.name | formatName}}</td>

                        <td class="adminText" width="60" v-if="activity.column === 'last_login_at' "></td>

                        <td class="adminText" width="60" v-else>
                            <button @click.prevet="toggleModal(activity)" class="admin__button admin__button--blue admin__button--small">Details</button>
                        </td>
                    </tr>
                </tbody>
                <modal></modal>
            </table>
        </div>
    </div>

</template>


<script>
    export default {
        props: ['loadActivities'],
        data(){
            return {
                activities: this.loadActivities
            };
        },
        filters: {
            formatDate(value) {
                if(value){
                    return moment(value).format('YYYY-MM-DD h:mm:ss a');
                }
                return "";
            },
            formatName(string){
                return string.replace('_', ' ');
            }
        },
        methods: {
            toggleModal(row) {
                EventBus.$emit('toggleModal', row);
            },

            getResults(page, popped) {
                if (typeof page === 'undefined') {
                    page = 1;
                }
                this.$http.get('/admin/activity?page=' + page + '&cache=' + Math.random()).then(response => {
                    if (!popped) {
                        window.history.pushState({page: page}, null, '/admin/activity?page=' + page);
                    }
                    this.$refs.pagination.$data.allow_processing = true;
                    this.activities = response.data;
                });
            }
        }
    }


</script>
<template>
    <div class="admin_stations">
        <div class="admin__controls">
            <a class="admin__button admin__button--blue admin__button--add" href="/admin/stations/create">Add Station</a>
            <pagination :data="stations" v-on:pagination-change-page="getResults" ref="pagination"></pagination>
        </div>
        <div v-if="stations.data.length > 0">
            <table>
                <thead>
                <tr>
                    <th>Station</th>
                    <th colspan="2" align="center">Action</th>
                </tr>
                <tr>
                    <td colspan="6" height="10"></td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="station in stations.data">
                    <td align="left" class="adminText">
                        <p><strong>{{ station.name }} </strong></p>
                        <p class="mobile-hidden">{{ station.description | truncate('100')}}</p>
                    </td>
                    <td align="center" width="60"><a v-bind:href=" 'stations/'+station.id+'/edit' " class="admin__button admin__button--blue admin__button--small">Edit</a></td>
                    <td align="center" width="60">
                        <button class="admin__button admin__button--small" v-on:click="destroy(station)">Delete</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            No Stations
        </div>
    </div>
</template>

<script>

    import states from '../states';

    function initForm() {
        return {
            open: false,
            mode: 'create',
            data: {
                name: '',
                city: '',
                state: '',
                description: ''
            }
        }
    }

    export default {
        props: ['loadStations'],
        data() {
            return {
                states: states,
                form: initForm(),
                stations: this.loadStations
            }
        },
        filters: {
            truncate(string, value) {
                return string.substring(0, value) + '...'
            }
        },
        methods: {
            destroy(station) {
                let confirmed = confirm(station.name + ' will be deleted! Are you sure?');

                if (confirmed) {
                    this.$http.delete('/admin/stations/' + station.id).then(() => {
                        let index = this.stations.data.indexOf(station);
                        this.stations.data.splice(index, 1);
                    }).catch((response) => {
                        alert(response.response.data.message)
                    });
                }
            },
            getResults(page, popped) {
                if (typeof page === 'undefined') {
                    page = 1;
                }
                this.$http.get('/admin/stations?page=' + page + '&cache=' + Math.random()).then(response => {
                    if (!popped) {
                        window.history.pushState({page: page}, null, '/admin/stations?page=' + page);
                    }
                    this.$refs.pagination.$data.allow_processing = true;
                    this.stations = response.data;
                });
            }
        }
    }
</script>

<template>
    <div v-show="mapLocations.length" class="leaflet-map__container-content">
        <h3 class="leaflet-map__container-content-title" v-html="'What\'s Nearby ' + mapTitle +'?'"></h3>
        <div class="leaflet-map__container-content-map" :id="mapId"></div>
    </div>
</template>

<script>
    import L from 'leaflet';

    export default {
        name: 'LeafletMap',
        props: {
            mapIcon: String,
            mapLocations: {
                type: Array,
                default() {
                    return [];
                }
            },
            mapTitle: String
        },
        data() {
            return {
                map: null,
                mapId: 'leaflet-map',
                layerGroup: null,
                zoom: 8
            };
        },
        mounted() {
            this.initMap();
        },
        watch: {
            mapLocations: async function (newValue, oldValue) {
                if (oldValue.length === 0) {
                    await this.$nextTick();
                    this.map.invalidateSize(true);
                }

                this.plotMarkers();
            }
        },
        methods: {
            initMap() {
                let osmAttrib = 'Map data &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';
                if ($(this.mapId).width() <= 780) {
                    this.zoom = 6;
                }

                this.map = L.map(this.mapId, {scrollWheelZoom: false})
                    .setMaxBounds(L.latLngBounds(new L.LatLng(37.998434, -104.001923), new L.LatLng(32.616270, -93.44)))
                    .addLayer(new L.tileLayer(`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`, {
                        minZoom: this.zoom,
                        maxZoom: 14,
                        zoomSnap: 0.5,
                        attribution: osmAttrib
                    }))
                    .on('click', () => {
                        this.map.scrollWheelZoom.enable();
                    })
                    .on('mouseover', () => {
                        this.map.scrollWheelZoom.enable();
                    })
                    .on('mouseout', () => {
                        this.map.scrollWheelZoom.disable();
                    });

                this.layerGroup = L.featureGroup().addTo(this.map);
                this.plotMarkers();
            },
            plotMarkers() {
                this.layerGroup.clearLayers();

                for (let location of this.mapLocations) {
                    let typeIcon = L.divIcon({
                        className: 'custom-div-icon',
                        html: '<div class="marker-pin"><div class="marker-pin-background"><img class="marker-pin-image" src="/images/map-icons/' + location.type + '.svg" alt="' + location.name + '"></div></div>',
                        iconSize: [46, 46],
                        iconAnchor: [46, 46],
                        popupAnchor: [-14, -40]
                    });

                    let listing = location.listing;

                    L.marker([listing.latitude, listing.longitude], {icon: typeIcon})
                        .addTo(this.layerGroup)
                        .bindPopup('<a class="itinerary-map__marker-title" href="' + listing.url + '" target="_blank">' + listing.name + '</a>', {
                            closeButton: false,
                            autoPanPaddingTopLeft: L.point(45, 5),
                            autoPanPaddingBottomRight: L.point(5, 45)
                        });
                }

                let bounds = L.latLngBounds(new L.LatLng(37.998434, -104.001923), new L.LatLng(32.616270, -93.44));

                if (this.mapLocations.length) {
                    bounds = this.layerGroup.getBounds();
                }

                this.map.fitBounds(bounds.pad(.5));
            }
        }
    };
</script>

<style scoped>
    @import "leaflet/dist/leaflet.css";
</style>
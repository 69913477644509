import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/models/dom';
import 'tinymce/plugins/link';
import 'tinymce/plugins/autolink';
import Vue from 'vue';
import MultiSelect from './components/Form/MultiSelect';
import BrightcoveVideoSearch from './components/Form/BrightcoveVideoSearch';
import FeaturedContent from './components/Form/FeaturedContent';
import BrightcoveVideoPlayer from './components/BrightcoveVideoPlayer';
import Stations from './components/Stations';
import Staff from './components/Staff';
import Categories from './components/Categories';
import Stories from './components/Stories';
import ListingSearch from './components/Form/ListingSearch';
import Pagination from './components/Pagination';
import Episodes from './components/Episodes';
import Users from './components/Users';
import Activities from './components/Activities';
import Modal from './components/Modal';
import LeafletMap from './components/LeafletMap';

window.$ = window.jQuery = require('jquery');
require('jquery-ui/ui/widgets/tooltip');
require('jquery-ui/ui/widgets/datepicker');
require('./modernizr');
require('./jquery.blockUI');

window.date_widget = require('./date_input_widget');
window.moment = require('moment');

window.EventBus = new Vue();
window.Vue = Vue;
Vue.prototype.$http = require('axios');
Vue.prototype.$http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Vue.prototype.$http.defaults.headers.common['Vary'] = 'X-Requested-With';

Vue.component('multi-select', MultiSelect);
Vue.component('brightcove-video-search', BrightcoveVideoSearch);
Vue.component('featured-content', FeaturedContent);
Vue.component('brightcove-video-player', BrightcoveVideoPlayer);
Vue.component('stations', Stations);
Vue.component('staff', Staff);
Vue.component('categories', Categories);
Vue.component('stories', Stories);
Vue.component('listing-search', ListingSearch);
Vue.component('pagination', Pagination);
Vue.component('episodes', Episodes);
Vue.component('users', Users);
Vue.component('activities', Activities);
Vue.component('modal', Modal);
Vue.component('leaflet-map', LeafletMap);

$(function () {
    $('.js-tooltip').tooltip({
        track: true,
        content: function () {
            var content = $(this).prop('title').split('|');
            if (content[1].length > 0) {
                content[1] += '&hellip;';
            }
            return '<h3>' + content[0] + '</h3>' + content[1];
        }
    });

    date_widget.default.ready();

    tinymce.init({
        skin: false,
        selector: '.js-tinymce',
        plugins: [
            'autolink',
            'link'
        ],
        menubar: false,
        statusbar: false,
        toolbar: 'link',
        forced_root_block: false,
        force_br_newlines: true,
        force_p_newlines: false,
        content_css: false
    });

    //Trigger now when you have selected any file
    $('.staff_upload').change(function (e) {
        e.preventDefault();

        var photo = this.files[0];

        var oFReader = new FileReader();
        oFReader.readAsDataURL(document.getElementById('photoUpload').files[0]);

        oFReader.onload = function (oFREvent) {
            document.getElementById('uploadPreview').src = oFREvent.target.result;

        };

    });

    //Activity Log Modal
    // TODO:  Simple implementation for now. need to grab info
    // from activity object and pass it to modal
    $('.modalTrigger').on('click', function () {
        $('#activityModal').addClass('modal__open');
    });

    $('.modalClose').on('click', function () {
        $('#activityModal').removeClass('modal__open');
    });

    //Character Counter
    $('.character_count').keyup(checkCharacterCount);
    $('.character_count').each(checkCharacterCount);

    function checkCharacterCount() {
        var max = $(this).attr('data-max');
        var min = $(this).attr('data-min');
        var len = $(this).val().length;
        if (len >= max) {
            $(this).closest('div').find('.charNum').text('You have reached the limit');
        } else {
            var char = max - len;
            var minV = min - len;

            if (minV > 0) {
                $(this).closest('div').find('.charNum').text('You have between ' + minV + ' and ' + char + ' characters left');
            } else {
                $(this).closest('div').find('.charNum').text(char + ' characters left');
            }

        }
    }
});
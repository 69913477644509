<template>
    <div class="multi-select">
        <input v-if="fallback" type="hidden" v-model="input" :name="name">

        <div class="multi-select__container">
            <label for="all-categories" class="form__label">{{ toLabel }}</label>
            <select id="all-categories" class="form__select-multiple" multiple="multiple" v-model="to">
                <option v-for="item in data" :value="item">{{ item.name }}</option>
            </select>
        </div>

        <div class="multi-select__container-buttons">
            <div class="multi-select__buttons">
                <button class="form__button" @click.prevent="add">&rarr;
                </button>
                <button class="form__button" @click.prevent="remove">
                    &larr;
                </button>
            </div>
        </div>

        <div class="multi-select__container">
            <label for="selected-categories" class="form__label">{{ fromLabel }}</label>
            <select id="selected-categories" class="form__select-multiple" multiple="multiple" v-model="from">
                <option v-for="item in selected" :value="item">{{ item.name }}</option>
            </select>
        </div>
        <div v-if="sortable" class="multi-select__sort">
            <button class="form__button" @click.prevent="up">&uarr;</button>
            <button class="form__button" @click.prevent="down">&darr;</button>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        props: {
            value: String,
            name: {
                required: true
            },
            data: {
                required: true
            },
            toLabel: String,
            fromLabel: String,
            sortable: {
                type: Boolean,
                default: false
            },
            fallback: {
                default: false
            },
            initValue: String
        },
        data() {
            return {
                input: this.initValue,
                to: [],
                from: [],
                selected: []
            };
        },
        created() {
            let vm = this;

            if (vm.fallback && vm.input !== '') {
                let initial = vm.input.split(',');

                initial.map(function (index) {
                    vm.selected.push(_.find(vm.data, function (o) {
                        return o.id === parseInt(index);
                    }));
                });
            }
        },
        watch: {
            selected(value) {
                let flattened = _.map(value, 'id').toString();

                this.input = flattened;
                this.$emit('value', flattened);
            }
        },
        methods: {
            add() {
                for (let i = 0, len = this.to.length; i < len; i++) {
                    let obj = this.to[i];
                    if (!this.isInArray(obj.id)) {
                        this.selected.push(obj);
                    }
                }
            },
            remove() {
                for (let i = 0, len = this.from.length; i < len; i++) {
                    let index = this.selected.indexOf(this.from[i]);
                    this.selected.splice(index, 1);
                }
            },
            up() {
                if (this.from.length === 1) {
                    let index = this.selected.indexOf(this.from[0]);
                    this.move(index, index - 1);
                }
            },
            down() {
                if (this.from.length === 1) {
                    let index = this.selected.indexOf(this.from[0]);
                    this.move(index, index + 1);
                }
            },
            isInArray(id) {
                return !!_.find(this.selected, function (o) {
                    return o.id === id;
                });
            },
            move(from, to) {
                if (to >= 0 && to <= this.selected.length) {
                    this.selected.splice(to, 0, this.selected.splice(from, 1)[0]);
                }
            }
        }
    };
</script>
<template>
    <div class="featured-content">
        <h3>Featured Content</h3>
        <button type="button" class="form__button admin__button--blue admin__button--right" @click="createFeaturedContent">Add Content</button>

        <div v-cloak v-if="featuredContents.length > 0">
            <table>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th colspan="2"><span>Actions</span></th>
                    </tr>
                </thead>
                <tr v-for="(featuredContent, key) in featuredContents">
                    <td class="text-column"><span>{{ featuredContent.title }}</span></td>
                    <td class="textarea-column"><span v-if="featuredContent.description !== '' && featuredContent.description !== null">{{ featuredContent.description.substring(0, 49) }}{{ featuredContent.description.length > 50 ? '&hellip;' : '' }}</span></td>
                    <td class="action-column">
                        <a href="javascript:;" @click="editFeaturedContent(key)" class="admin__button admin__button--blue admin__button--small">Edit</a>
                        <input type="hidden" :name="'featured_content[' + key + '][id]'" v-model="featuredContent.id">
                        <input type="hidden" :name="'featured_content[' + key + '][title]'" v-model="featuredContent.title">
                        <input type="hidden" :name="'featured_content[' + key + '][description]'" v-model="featuredContent.description">
                        <input type="hidden" :name="'featured_content[' + key + '][website]'" v-model="featuredContent.website">
                        <div v-for="(nearby_listing, index) in featuredContent.nearby_listings">
                            <input type="hidden" :name="'featured_content[' + key + '][nearby_listings][' + index + '][id]'" v-model="nearby_listing.id">
                            <input type="hidden" :name="'featured_content[' + key + '][nearby_listings][' + index + '][listing_id]'" v-model="nearby_listing.listing_id">
                            <input type="hidden" :name="'featured_content[' + key + '][nearby_listings][' + index + '][type]'" v-model="nearby_listing.type">
                        </div>
                    </td>
                    <td class="action-column">
                        <button class="admin__button admin__button--small admin__button--danger" @click="deleteFeaturedContent(key)">Delete</button>
                    </td>
                </tr>
            </table>
        </div>
        <div v-else>
            <p>No Featured Content. <a href="javascript:" @click="createFeaturedContent">Add one now.</a></p>
        </div>

        <div v-on:close="onModalClose" v-on:after-enter="onModalOpen" class="modal micromodal-slide" id="modal-featured-content" aria-hidden="true">
            <form ref="featuredContentForm">
                <div class="modal__overlay" tabindex="-1">
                    <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-featured-content-title">
                        <header class="modal__header">
                            <h2 class="modal__title" id="modal-featured-content-title">Featured Content</h2>
                            <button class="modal__close" aria-label="Close modal" @click="onModalClose" type="button"></button>
                        </header>
                        <main class="modal__content" id="modal-featured-content-content">
                            <div class="form__group">
                                <div v-if="featured_content.image">
                                    <label class="form__label">Existing Image</label>
                                    <img :src="imageSrc" :alt="featured_content.title">
                                </div>
                            </div>
                            <div class="form__group">
                                <label class="form__label">New Image</label>
                                <input id="featured_content_file" class="form__file" ref="featured_content_image" accept="image/png,image/jpeg,image/webp" type="file">
                            </div>
                            <div class="form__group">
                                <label class="form__label">Title <span class="form__required">*</span></label>
                                <input required type="text" class="form__input character_count" placeholder="Example: Phat Tabb's BBQ" v-model="featured_content.title" data-max="70">
                                <div class="charNum"></div>
                            </div>
                            <div class="form__group">
                                <label class="form__label">Website <span class="form__required">*</span></label>
                                <input required type="text" class="form__input" placeholder="Example: https://www.travelok.com" v-model="featured_content.website">
                            </div>
                            <div class="form__group">
                                <label class="form__label">Description <span class="form__required">*</span></label>
                                <textarea required class="form__textarea character_count" v-model="featured_content.description" rows="7" data-max="156"></textarea>
                                <div class="charNum"></div>
                            </div>
                            <hr>
                            <div class="form__group">
                                <listing-search
                                    :data="featured_content.nearby_listings"
                                    title="Nearby Listings"
                                    :select-listing="selectListing"
                                    :modal-id="'nearby-listings-' + index"
                                ></listing-search>
                            </div>
                        </main>
                        <footer class="modal__footer">
                            <button type="button" class="form__button form__button--green" @click="save">Save</button>
                        </footer>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import MicroModal from 'micromodal';

    const emptyFeaturedContent = {
        id: null,
        title: '',
        image: null,
        description: null,
        website: null,
        episode: null,
        nearby_listings: [],
        files: null
    };

    export default {
        name: 'FeaturedContent',
        props: {
            featuredContents: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            episodeId: {
                type: Number,
                default: 1
            },
            imagePath: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                is_active: false,
                featured_content: structuredClone(emptyFeaturedContent),
                index: 0
            };
        },
        created() {
            MicroModal.init();
        },
        computed: {
            imageSrc() {
                return this.featured_content.files ? this.featured_content.image : this.imagePath + this.featured_content.image;
            }
        },
        methods: {
            onModalClose() {
                MicroModal.close('modal-featured-content');
            },
            onModalOpen() {
                this.$refs.featured_content_image.value = null;
                // Populate file field once $refs.featured_content_image exists
                if (typeof (this.featured_content.files) !== 'undefined' && this.featured_content.files !== null && this.featured_content.files.length > 0) {
                    this.$refs.featured_content_image.files = structuredClone(this.featured_content.files);
                }
                MicroModal.show('modal-featured-content');
            },
            createFeaturedContent() {
                this.featured_content = structuredClone(emptyFeaturedContent);
                this.index = this.featuredContents.length;
                this.featured_content.episode = this.episodeId;
                this.onModalOpen();
            },
            editFeaturedContent(index) {
                this.index = index;
                this.featured_content = structuredClone(this.featuredContents[index]);
                this.onModalOpen();
            },
            deleteFeaturedContent(index) {
                let vm = this;
                let confirmed = confirm('"' + this.featuredContents[index].title + '" will be deleted! Are you sure?');

                if (confirmed) {
                    vm.featuredContents.splice(index, 1);
                }
            },
            async save() {
                // Attempt form validation
                if (!this.$refs.featuredContentForm.checkValidity()) {
                    this.$refs.featuredContentForm.reportValidity();
                    return false;
                }

                const file = this.$refs.featured_content_image.files;
                if (file.length > 0) {
                    this.featured_content.files = structuredClone(file);
                    this.featured_content.image = await this.loadImage(file[0]);
                }

                if (!this.featured_content.image) {
                    alert('Please upload an image');
                    document.getElementById('featured_content_file').scrollIntoView();
                    return false;
                }

                this.$set(this.featuredContents, this.index, structuredClone(this.featured_content));
                this.onModalClose();
            },
            loadImage(file) {
                return new Promise(async function (resolve, reject) {
                    let fr = new FileReader();
                    fr.onload = function () {
                        resolve(fr.result);
                    };
                    fr.onerror = function () {
                        reject(fr);
                    };
                    fr.readAsDataURL(file);
                });
            },
            selectListing(listing) {
                this.featured_content.nearby_listings.push(listing);
            }
        }
    };
</script>
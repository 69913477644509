<template>
    <div class="modal fade" id="activityModal" :class="{'modal__open' : active}" v-if="activity.user">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="activity-user">
                        <p class="adminText"><span class="bold">{{activity.user.username}}</span> changed the following:</p>
                    </div>
                    <div class="activity-change">
                        <p class="adminText"></p>
                        <table>
                            <thead>
                            <tr v-if="activity.name === 'updated_user'">
                                <td colspan="6" height="10" align="center">
                                    <h4>{{activity.name | formatName }} {{activity.subject.username}} {{activity.column}}</h4>
                                </td>
                            </tr>
                            <tr v-else="">
                                <td colspan="6" height="10" align="center">
                                    <h4>{{activity.name | formatName }} {{activity.subject_id}} {{activity.column}}</h4>
                                </td>
                            </tr>
                            <tr>
                                <th class="adminText">From</th>
                                <th class="adminText">To</th>

                            </tr>
                            </thead>
                            <tbody>
                            <tr height="30">
                                <td class="adminText">{{activity.original_content}}</td>
                                <td class="adminText">{{activity.updated_content}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click.prevent="active = false" type="button" class="admin__button admin__button--blue modalClose">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                active: false,
                activity: false,
            };
        },
        filters: {
            formatDate(value) {
                if(value){
                    return moment(value).format('YYYY-MM-DD');
                }
                return "";
            },
            formatName(string){
                return string.replace('_', ' ');
            }
        },
        methods: {
            show() {
                this.active = true;
            }
        },
        created() {
            EventBus.$on('toggleModal', (row) => {
                this.activity = row;
                this.show();
            });
        }
    }

</script>
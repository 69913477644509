<template>
    <div>
        <div class="admin__controls">
            <a class="admin__button admin__button--blue" href="/admin/episodes/create">Add Episode</a>
            <pagination :data="episodes" v-on:pagination-change-page="getResults" ref="pagination"></pagination>
        </div>
        <table>
            <thead>
                <tr>
                    <th>Title</th>
                    <th class="air-date">Air Date</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="episode in episodes.data">
                    <td><a :href="'/episode/' + episode.slug" target="_blank"><span v-if="episode.number !== ''">#{{ episode.number }} </span>{{ episode.title }}</a></td>
                    <td class="air-date"><span>{{ episode.air_date | formatDate }}</span></td>
                    <td>
                        <a class="admin__button admin__button--blue admin__button--small" :href="'/admin/episodes/' + episode.id + '/edit'">Edit</a>
                        <button class="admin__button admin__button--danger admin__button--small" @click="destroy(episode)">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: ['loadEpisodes'],
        data() {
            return {
                episodes: this.loadEpisodes
            };
        },
        filters: {
            formatDate(value) {
                if (!value) {
                    return 'N/A';
                }

                return new Date().toISOString().split('T')[0];
            }
        },
        methods: {
            getResults(page, popped) {
                if (typeof page === 'undefined') {
                    page = 1;
                }

                // Using vue-resource as an example
                this.$http.get('/admin/episodes?page=' + page + '&cache=' + Math.random()).then(response => {
                    if (!popped) {
                        window.history.pushState({page: page}, null, '/admin/episodes?page=' + page);
                    }

                    this.$refs.pagination.$data.allow_processing = true;
                    this.episodes = response.data;
                });
            },
            destroy(episode) {
                let vm = this;
                let confirmed = confirm(episode.title + ' will be deleted! Are you sure?');

                if (confirmed) {
                    this.$http.delete('/admin/episodes/' + episode.id).then(() => {
                        let index = vm.episodes.data.indexOf(episode);
                        vm.episodes.data.splice(index, 1);
                    }).catch((response) => {
                        alert(response.response.data.message);
                    });
                }
            }
        }
    };
</script>

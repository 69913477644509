<template>
    <div class="listing-search">
        <h3 v-if="hasOneListing" v-html="title"></h3>
        <label v-else v-html="title"></label>
        <button type="button" class="form__group-half form__button admin__button--blue admin__button--right listing-search__button" @click="addListing" v-html="button_text"></button>

        <div v-cloak v-if="listings.length > 0">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th v-if="!hasOneListing">Type</th>
                        <th><span>&nbsp;</span></th>
                    </tr>
                </thead>
                <tr v-for="(listing, key) in listings">

                    <td><span>{{ listing.listing_id }}</span></td>
                    <td><span>{{ listing.name }}</span></td>
                    <td v-if="!hasOneListing">
                        <select class="form__select" v-model="listing.type">
                            <option selected value="attractions">Nearby Attractions</option>
                            <option value="stay">Places to Stay</option>
                        </select>
                    </td>
                    <td class="action-column">
                        <button class="admin__button admin__button--small admin__button--danger" type="button" @click="removeListing(key, listing)">Delete</button>
                        <input type="hidden" :name="name" v-model="listing.listing_id">
                    </td>
                </tr>
            </table>
        </div>
        <div v-else>
            <p>No <span v-html="title"></span>. <a href="javascript:" @click="addListing">Add one now.</a></p>
        </div>

        <div class="modal micromodal-slide" :id="'modal-' + modalId" aria-hidden="true">
            <form>
                <div class="modal__overlay" tabindex="-1">
                    <div class="modal__container" role="dialog" aria-modal="true" :aria-labelledby="'modal-' + modalId + '-title'">
                        <header class="modal__header">
                            <h2 class="modal__title" :id="'modal-' + modalId + '-title'">Listing Search</h2>
                            <button class="modal__close" aria-label="Close" type="button" @click="onModalClose"></button>
                        </header>

                        <main class="modal__content" :id="'modal-' + modalId + '-content'">
                            <div class="form__group listing-search__search-form">
                                <input class="form__input" type="text" @keydown.enter.prevent @keyup.enter="searchListings" v-model="search.term" />
                                <input class="form__button" type="button" id="search_listings_btn" @click="searchListings" value="Search" />
                            </div>

                            <hr>

                            <div class="listing-search__list">
                                <div v-if="search.results.length === 0" v-html="search.status"></div>
                                <div v-cloak v-else>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tr v-for="result in search.results">
                                            <td><span>{{ result.listing_id }}</span></td>
                                            <td><span>{{ result.name }}</span></td>
                                            <td class="action-column">
                                                <a v-if="!isAdded(result.listing_id)" href="javascript:;" @click="selectListing(result)" class="admin__button admin__button--blue admin__button--small" v-html="select_text"></a>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import MicroModal from 'micromodal';

    export default {
        name: 'ListingSearch',
        props: {
            data: {
                type: Array,
                required: false
            },
            name: {
                type: String,
                default: 'listing'
            },
            hasOneListing: {
                type: Boolean,
                default: false
            },
            title: String,
            isAdded: {
                type: Function,
                default: function (id) {
                    return this.listings.some(listing => listing.listing_id === id);
                }
            },
            selectListing: {
                type: Function,
                default: function (listing) {
                    this.listings.push(listing);
                }
            },
            modalId: String
        },
        data() {
            return {
                is_active: false,
                search: {
                    term: '',
                    status: '',
                    results: []
                }
            };
        },
        created() {
            MicroModal.init();
        },
        computed: {
            button_text() {
                let text = 'Add';

                if (this.hasOneListing && this.listings.length > 0) {
                    text = 'Update';
                }

                return text;
            },
            select_text() {
                return this.hasOneListing ? 'Select' : 'Add';
            },
            listings() {
                return this.data;
            }
        },
        methods: {
            onModalOpen() {
                MicroModal.show('modal-' + this.modalId);
            },
            onModalClose() {
                MicroModal.close('modal-' + this.modalId);
            },
            addListing() {
                this.search.term = '';
                this.search.results = [];
                this.onModalOpen();
            },
            removeListing(key, listing) {
                const vm = this;
                let confirmed = confirm(listing.name + ' will be removed! Are you sure?');

                if (confirmed) {
                    vm.$delete(vm.listings, key);
                }
            },
            searchListings() {
                let vm = this;
                let searchTerm = this.search.term;
                this.search.results = [];

                if (searchTerm === '') {
                    this.search.status = 'Please enter search term.';
                } else {
                    this.search.status = 'Please wait while we search ....';
                    this.$http.get('/admin/de/search-listings/' + searchTerm).then(function (response) {
                        if (response.data.length > 0) {
                            vm.search.status = '';
                            vm.search.results = response.data;
                        } else {
                            vm.search.status = 'Sorry, no results match your term.';
                        }
                    });
                }
            }
        }
    };
</script>
<template>
    <div>
        <div class="admin__controls">
            <a class="admin__button admin__button--blue admin__button--add" href="/admin/staff/create">Add Staff</a>
            <pagination :data="staff" v-on:pagination-change-page="getResults" ref="pagination"></pagination>
        </div>
        <div v-if="staff.data.length > 0">
            <table>
                <thead>
                <tr>
                    <th  class="adminText">Staff Name</th>
                    <th  class="adminText">Staff Title</th>
                    <th colspan="2" align="center" class="adminText">Action</th>
                </tr>
                <tr>
                    <td colspan="6" height="10"></td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="person in staff.data" height="30">
                    <td align="center" class="adminText">{{ person.first_name }} {{person.last_name}}</td>
                    <td align="center" class="adminText"> {{ person.title }}</td>
                    <td align="center" class="adminText" width="60"><a v-bind:href="'staff/'+person.slug + '/edit'" class="admin__button admin__button--blue admin__button--small">Edit</a></td>
                    <td align="center" class="adminText" width="60"><button class="admin__button admin__button--small" v-on:click="destroy(person)">Delete</button></td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-else="" class="">
            No Staff
        </div>
    </div>
</template>

<script>
  import moment from 'moment';

  export default {
    props: ['loadStaff'],
    data() {

      return {
        staff: this.loadStaff,
      }
    },
    filters: {
      truncate(string, value) {
        return string.substring(0, value) + '...'
      }
    },
    methods: {
      destroy(staff) {
        let confirmed = confirm(staff.first_name + ' ' + staff.last_name + ' will be deleted! Are you sure?');

        if (confirmed) {
          this.$http.delete('/admin/staff/' + staff.slug).then( () => {
            let index = this.staff.data.indexOf(staff);
          this.staff.data.splice(index, 1);
        }).catch((response) => {
              alert(response.response.data.message)
          });
        }
      },
      getResults(page, popped) {
        if (typeof page === 'undefined') {
          page = 1;
        }

        // Using vue-resource as an example
        this.$http.get('/admin/staff?page=' + page + '&cache=' + Math.random()).then(response => {
          if (!popped) {
          window.history.pushState({page: page}, null, '/admin/staff?page=' + page);
        }

        this.$refs.pagination.$data.allow_processing = true;
        this.staff = response.data;
      });
      }

    }
  }
</script>
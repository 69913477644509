<template>
    <div v-show="data.total > data.per_page" class="pagination">
        <button class="admin__button admin__button--blue" v-if="data.prev_page_url" @click.prevent="selectPage(--data.current_page)">
            &laquo; Prev
        </button>
        <label>
            Page
            <select class="pagination__select" name="" id="" v-model="current_page" @change="selectPage(current_page)">
                <option v-for="n in getPages()" :value="n">{{ n }}</option>
            </select>
             of {{ data.last_page }}
        </label>
        <button class="admin__button admin__button--blue" v-if="data.next_page_url" @click.prevent="selectPage(++data.current_page)">
            Next &raquo;
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            data: {
                type: Object,
                default: function () {
                    return {
                        current_page: 1,
                        data: [],
                        from: 1,
                        last_page: 1,
                        next_page_url: null,
                        per_page: 10,
                        prev_page_url: null,
                        to: 1,
                        total: 0,
                    };
                }
            }
        },
        data() {
            return {
                current_page: this.data.current_page,
                allow_processing: true
            };
        },
        methods: {
            selectPage: function (page) {
                if (this.allow_processing === true) {
                    this.allow_processing = false;
                    this.current_page = page;
                    this.$emit('pagination-change-page', page);
                }
            },
            getPages: function () {
                let pages = [];

                let end = this.data.last_page;

                for (let index = 1; index <= end; index++) {
                    pages.push(index);
                }

                return pages;
            }
        }
    };
</script>
var date_input_widget = (function() {
	var module = {};

	/**
	 * Ready() - Called on page load
	 */
	module.ready = function(ctx) {

		if (!Modernizr.inputtypes.date) {
			$("input[type='date']").parent(".form__date").addClass("form__date--no-support");
			$("input[type='date']").datepicker({
				dateFormat: "yy-mm-dd",
				buttonImage: '/images/shell/CalendarIcon.gif',
				showOn: 'both',
				duration: 'fast',
				currentText: "",
				buttonImageOnly: true
			});
		} else {
			// The expected date returned does not conform to the format "yyyy-MM-dd", per ISO 8601
			// however, chrome appears to use locale to show the appropriate date format.
			// Format date for native date pickers if a value is provided
			$("input[type='date']").each(function() {
				var date = $(this).attr("value");
				if (date !== "") {
					function pad(s) { return (s < 10) ? '0' + s : s; }

                    var d = new Date(date);
					if(date.indexOf("-") !== -1){
                        d = new Date(date.split("-"));
					}
					var newDate = [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
					$(this).val(newDate);
				}
			});
		}
	};

	return module;
});

export default new date_input_widget();
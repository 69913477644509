<template>
    <div class="brightcove-video-search">
        <div class="columns">
            <div :class="{'form__group' : !id}" class="form__group-half">
                <div class="form__group">
                    <label for="brightcove_id" class="form__label">Brightcove Video</label>
                    <brightcove-video-player v-if="id !== null" :video_id="video.id"></brightcove-video-player>
                    <h4 class="brightcove-video-search__video-name" v-html="video.name"></h4>
                </div>
            </div>

            <div v-if="id" class="form__group-half">
                <label class="form__label">Image</label>
                <img class="brightcove-player" :src="video.image" :alt="video.name">
                <label for="update-image" class="form__image-checkbox">
                    <input type="checkbox" name="update_image" id="update-image"> Update Image from Brightcove
                </label>
            </div>
        </div>

        <div v-if="hasTags && id" class="form__group brightcove-video-search__tags">
            <label for="brightcove_tags" class="form__label">Brightcove Tags (Separate each tag with a comma)</label>
            <input type="text" id="brightcove_tags" class="form__input" name="brightcove_tags" v-model="video.tags" />
        </div>

        <div class="form__group brightcove-video-search__container">
            <button class="form__button admin__button--blue" @click.prevent="showUnassignedVideos">
                {{ button_text }}
            </button>
            <img v-show="loading" class="brightcove-video-search__loader" src="/images/shell/ajax-loader.gif" />
            <span class="brightcove-video-search__forget-videos" v-show="loaded" @click.prevent="forgetVideos"> - Can't find your video? Click Here!</span>
            <input type="hidden" name="brightcove_id" id="brightcove_id" v-model="video.id">
        </div>

        <div class="form__group">
            <div v-show="error !== ''" v-html="error" class="brightcove-video-search__video-error"></div>
            <div v-show="results.length > 0" class="brightcove-video-search__bc-video-list">
                <div v-for="(result, index) in results" @click="selectVideo(result, $event)" :class="[{'brightcove-video-search__bc-video-item--alt': index % 2 === 0}, 'brightcove-video-search__bc-video-item', 'clearfix']">
                    <img class="brightcove-video-search__bc-video-item-thumbnail" :src="result.images.thumbnail.src">
                    <div>{{ result.name }}</div>
                    <div>Video ID: {{ result.id }}</div>
                    <div>Tags: {{ result.tags.join(', ') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            hasTags: {
                type: Boolean,
                default: false
            },
            tags: {
                type: String,
                default: null
            },
            id: {
                type: Number,
                default: null
            },
            name: {
                type: String,
                default: null
            },
            videoImage: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                error: null,
                loading: false,
                loaded: false,
                results: [],
                activeLink: null,
                video: {
                    id: this.id,
                    name: this.name,
                    image: this.videoImage,
                    tags: this.tags
                }
            };
        },
        computed: {
            button_text() {
                return (this.loading) ? 'Fetching Videos...' : 'Choose Brightcove Video';
            }
        },
        methods: {
            showUnassignedVideos() {
                let vm = this;

                this.error = null;
                this.loading = true;
                this.results = [];

                this.$http.get('/admin/bc/unassigned-videos').then(function (r) {
                    vm.results = r.data;
                }).catch(function (e) {
                    if (typeof e.response.data.message !== 'undefined') {
                        vm.error = e.response.data.message;
                    }
                }).then(function () {
                    vm.loading = false;
                    vm.loaded = true;
                });
            },
            forgetVideos() {
                let vm = this;

                this.error = null;
                this.loading = true;
                this.results = [];

                this.$http.get('/admin/bc/forget-videos')
                    .then(function () {
                        vm.$http.get('/admin/bc/unassigned-videos').then(function (r) {
                            vm.results = r.data;
                        }).catch(function (e) {
                            if (typeof e.response.data.message !== 'undefined') {
                                vm.error = e.response.data.message;
                            }
                        }).then(function () {
                            vm.loading = false;
                            vm.loaded = true;
                        });
                    });
            },
            selectVideo(video, e) {
                let selectClass = 'brightcove-video-search__bc-video-item--selected';
                let newActiveLink = e.currentTarget;

                if (this.activeLink && this.activeLink === newActiveLink) {
                    this.activeLink.classList.remove(selectClass);
                    this.activeLink = null;

                    this.video.id = this.id;
                    this.video.name = this.name;
                    this.video.image = this.videoImage;
                    this.video.tags = this.tags;
                } else {
                    if (this.activeLink) {
                        this.activeLink.classList.remove(selectClass);
                    }

                    this.activeLink = newActiveLink;
                    this.activeLink.classList.add(selectClass);

                    this.video.id = parseInt(video.id);
                    this.video.name = video.name;
                    this.video.image = video.images.thumbnail.src;

                    if (this.hasTags) {
                        this.video.tags = video.tags.join(', ');
                    }
                }
            }
        }
    };
</script>
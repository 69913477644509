<template>
    <div>
        <div class="admin__controls">
            <a class="admin__button admin__button--blue admin__button--add" href="/admin/categories/create">Add Category</a>
            <pagination :data="categories" v-on:pagination-change-page="getResults" ref="pagination"></pagination>
        </div>
        <table v-if="categories.data.length > 0">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="category in categories.data">
                    <td>{{ category.name }}</td>
                    <td class="action-column">
                        <a class="admin__button admin__button--blue admin__button--small" :href="'/admin/categories/' + category.id + '/edit'">Edit</a>
                        <button class="admin__button admin__button--small admin__button--danger" @click.prevent="destroy(category)">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <p v-else>No Categories</p>
    </div>
</template>

<script>

    export default {
        props: ['loadCategories'],
        data() {
            return {
                categories: this.loadCategories
            };
        },
        methods: {
            getResults(page, popped) {
                if (typeof page === 'undefined') {
                    page = 1;
                }

                // Using vue-resource as an example
                this.$http.get('/admin/categories?page=' + page + '&cache=' + Math.random()).then(response => {
                    if (!popped) {
                        window.history.pushState({page: page}, null, '/admin/categories?page=' + page);
                    }

                    this.$refs.pagination.$data.allow_processing = true;
                    this.categories = response.data;
                });
            },
            destroy(category) {
                let vm = this;
                let confirmed = confirm(category.name + ' will be deleted! Are you sure?');

                if (confirmed) {
                    this.$http.delete('/admin/categories/' + category.id).then(() => {
                        let index = vm.categories.data.indexOf(category);
                        vm.categories.data.splice(index, 1);
                    }).catch((response) => {
                        alert(response.response.data.message);
                    });
                }
            }
        }
    };
</script>

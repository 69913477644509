<template>
    <div>
        <div class="admin__controls">
            <a class="admin__button admin__button--blue" href="/admin/stories/create">Add story</a>
            <pagination :data="stories" v-on:pagination-change-page="getResults" ref="pagination"></pagination>
        </div>
        <div class="admin__search">
            <input class="form__input admin__search-input" ref="search_term" type="search" name="search_term" maxlength="80" placeholder="Search">
            <a class="admin__button admin__button--blue" href="javascript:;" @click="searchStories">Search</a>
        </div>
        <table>
            <thead>
                <tr>
                    <th>Story Title</th>
                    <th>Date Shot</th>
                    <th class="air-date">Air Date(s)</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="story in stories.data">
                    <td><a :href="'/stories/' + story.id">{{ story.title }}</a></td>
                    <td class="stories-admin__date-shot">{{ story.date_shot | formatDate }}</td>
                    <td class="air-date">
                        <div class="stories-admin__air-dates" v-for="episode in story.episodes">{{ episode.air_date | formatDate }} [<a :href="'/episodes/' + episode.id">{{ episode.id }}</a>]</div>
                    </td>
                    <td>
                        <a class="admin__button admin__button--blue admin__button--small" :href="'/admin/stories/' + story.id + '/edit'">Edit</a>
                        <button class="admin__button admin__button--danger admin__button--small" @click.prevent="destroy(story)">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: ['loadStories'],
        data() {
            return {
                stories: this.loadStories,
                page: 1,
                searchTimeout: null
            };
        },
        filters: {
            formatDate(value) {
                if (!value) {
                    return 'N/A';
                }

                return new Date().toISOString().split('T')[0];
            }
        },
        methods: {
            getResults(page, popped) {
                this.page = page;
                if (typeof page === 'undefined') {
                    this.page = 1;
                }

                // Using vue-resource as an example
                this.$http.get('/admin/stories?page=' + page + '&cache=' + Math.random()).then(response => {
                    if (!popped) {
                        window.history.pushState({page: page}, null, '/admin/stories?page=' + page);
                    }
                    this.$refs.pagination.$data.allow_processing = true;
                    this.stories = response.data;
                });
            },
            searchStories() {
                let value = this.$refs.search_term.value;
                let timeout = 500;

                if (value !== '') {
                    if (value.length > 1) {
                        if (this.searchTimeout) {
                            clearTimeout(this.searchTimeout);
                        }
                        this.searchTimeout = setTimeout(() => {
                            this.$http.get('/admin/stories/search/' + value).then(response => {
                                this.stories = response.data;
                            });
                        }, timeout);
                    }
                } else {
                    this.getResults(this.page);
                }
            },
            destroy(story) {
                let confirmed = confirm('"' + story.title + '" will be deleted! Are you sure?');

                if (confirmed) {
                    this.$http.delete('/admin/stories/' + story.id).then(() => {
                        let index = this.stories.data.indexOf(story);
                        this.stories.data.splice(index, 1);
                    }).catch((response) => {
                        alert(response.response.data.message);
                    });
                }
            }
        }
    };
</script>
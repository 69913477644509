<template>
    <div v-show="player.video_id" class="brightcove-player">
        <script type="application/javascript" :src="'https://players.brightcove.net/' + player.account_id + '/' + player.player_id + '_default/index.min.js'"></script>
        <video-js
            class="brightcove-player__video vjs-fluid"
            :data-account="player.account_id"
            :data-player="player.player_id"
            data-embed="default"
            controls=""
            :data-video-id="player.video_id"
            data-playlist-id=""
            data-application-id=""></video-js>
    </div>
</template>

<script>
    export default {
        name: 'BrightcoveVideoPlayer',
        props: {
            video_id: 0
        },
        data() {
            return {
                player: {
                    video_id: this.video_id,
                    player_id: this.$root.video_player_id,
                    account_id: this.$root.video_player_account_id
                }
            };
        }
    };
</script>